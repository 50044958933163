import { wrapView } from '../smart_bps/lib/utils'
import { reportStateToProps } from '../smart_bps/report/index_view'
import { ReportBaseView } from '../smart_bps/report/report_view'


class ReportView extends ReportBaseView {
  name = 'support'

  constructor(props) {
    super(props)
  }

  setContext(src, context) {
    this.setState({
      context: context,
      table_data: { ...this.state.table_data, "details": undefined }
    })
  }

  renderContent() {
    const context = this.state.context

    if (context.details) {
      return (
        <div className="card">
          <div className="card-body p-3">
            {this.renderTable({
              data_name: "details",
              links: { "id": (row) => { return `https://redmine.sbps.ru/issues/${row.id}` } }
            })}
          </div>
        </div>
      )
    }

    return (
      <div className="row">
        <div className="col-md-8">

          <div className="card mb-3">
            <div className="card-body p-3">
              <h5 className="card-title">Бэклог</h5>
              <div className="card-text">
                {this.renderTable({
                  data_name: "backlog",
                  change_context: {
                    "До 1": (row) => { return { "details": "backlog", "priority_id": row._priority_id, "to_hours": 1 } },
                    "От 1 до 3": (row) => { return { "details": "backlog", "priority_id": row._priority_id, "from_hours": 1, "to_hours": 3 } },
                    "От 3 до 6": (row) => { return { "details": "backlog", "priority_id": row._priority_id, "from_hours": 3, "to_hours": 6 } },
                    "От 6 до 24": (row) => { return { "details": "backlog", "priority_id": row._priority_id, "from_hours": 6, "to_hours": 24 } },
                    "Выше 24": (row) => { return { "details": "backlog", "priority_id": row._priority_id, "from_hours": 24 } },
                    "Итого": (row) => { return { "details": "backlog", "priority_id": row._priority_id } },
                  }
                })}
              </div>
            </div>
          </div>

          <div className="card mb-3">
            <div className="card-body p-3">
              <h5 className="card-title">В работе (у разработчиков)</h5>
              <div className="card-text">
                {this.renderTable({
                  data_name: "work_developer",
                  change_context: {
                    "До 1": (row) => { return { "details": "work_developer", "priority_id": row._priority_id, "to_hours": 1 } },
                    "От 1 до 3": (row) => { return { "details": "work_developer", "priority_id": row._priority_id, "from_hours": 1, "to_hours": 3 } },
                    "От 3 до 6": (row) => { return { "details": "work_developer", "priority_id": row._priority_id, "from_hours": 3, "to_hours": 6 } },
                    "От 6 до 24": (row) => { return { "details": "work_developer", "priority_id": row._priority_id, "from_hours": 6, "to_hours": 24 } },
                    "Выше 24": (row) => { return { "details": "work_developer", "priority_id": row._priority_id, "from_hours": 24 } },
                    "Итого": (row) => { return { "details": "work_developer", "priority_id": row._priority_id } },
                  }
                })}
              </div>
            </div>
          </div>

          <div className="card mb-3">
            <div className="card-body p-3">
              <h5 className="card-title">В работе (у заказчиков)</h5>
              <div className="card-text">
                {this.renderTable({
                  data_name: "work_customer",
                  change_context: {
                    "До 1": (row) => { return { "details": "work_customer", "priority_id": row._priority_id, "to_hours": 1 } },
                    "От 1 до 3": (row) => { return { "details": "work_customer", "priority_id": row._priority_id, "from_hours": 1, "to_hours": 3 } },
                    "От 3 до 6": (row) => { return { "details": "work_customer", "priority_id": row._priority_id, "from_hours": 3, "to_hours": 6 } },
                    "От 6 до 24": (row) => { return { "details": "work_customer", "priority_id": row._priority_id, "from_hours": 6, "to_hours": 24 } },
                    "Выше 24": (row) => { return { "details": "work_customer", "priority_id": row._priority_id, "from_hours": 24 } },
                    "Итого": (row) => { return { "details": "work_customer", "priority_id": row._priority_id } },
                  }
                })}
              </div>
            </div>
          </div>


        </div>
        <div className="col-md-4">
          <div className="card mb-3">
            <div className="card-body p-3">
              <h5 className="card-title">Трудозатраты за 6 мес</h5>
              <div className="card-text">
                {this.renderTable({
                  data_name: "work_hours",
                  change_context: {
                    "До 1": (row) => { return { "details": "work_hours"} },
                    "От 1 до 3": (row) => { return { "details": "work_hours" } },
                    "От 3 до 6": (row) => { return { "details": "work_hours" } },
                    "От 6 до 24": (row) => { return { "details": "work_hours" } },
                    "Выше 24": (row) => { return { "details": "work_hours" } },
                    "Итого": (row) => { return { "details": "work_hours", } },
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default wrapView(ReportView, reportStateToProps)
import { wrapView } from '../smart_bps/lib/utils'
import { reportStateToProps } from '../smart_bps/report/index_view'
import { ReportBaseView } from '../smart_bps/report/report_view'


class ReportView extends ReportBaseView {
  name = 'employee_work'

  constructor(props) {
    super(props)
  }

  renderContent() {
    const thead = (
      <thead>
        <tr>
          <th colSpan="3" className="text-center">Сотрудник</th>
          <th colSpan="6" className="text-center">Redmine</th>
          <th colSpan="3" className="text-center">Git</th>
        </tr>
        <tr>
          <th>Фамилия</th>
          <th>Имя</th>
          <th>Стаж</th>

          <th>Комментарии</th>
          <th>Часов общих</th>
          <th>Часов внутренних</th>
          <th>Эффективных часов</th>
          <th>Часов по периоду</th>
          <th>Дней в периоде</th>

          <th>Строк в час</th>
          <th>Коммитов</th>
          <th>Слияний</th>
        </tr>
      </thead>
    )

    const teams = [
      ["vetro", "Ветро"],
      ["sber_crm", "Сбер CRM"],
      ["sber_1", "Сбер (Ронжин)"],
      ["sber_2", "Сбер (Королев)"],
      ["sber_3", "Сбер (Куликов)"],
      ["analyst", "Аналитика"],
      ["reports", "Отчеты"],
      ["infr", "Инфраструктура"],
      ["sber", "Сбербанк"],
      ["other", "Прочее"],
    ]

    return teams.map((team) => {
      const team_name = team[0]
      return <div className="card mb-3" key={team_name}>
        <div className="card-body p-3">
          <h5 className="card-title">{team[1]}</h5>
          <div className="card-text">
            {this.renderTable({ data_name: "dev", add_context: { team: team_name }, thead: thead })}
          </div>
        </div>
      </div>
    })
  }
}


export default wrapView(ReportView, reportStateToProps)
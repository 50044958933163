/* global toastr moment */

import React from 'react'

import { baseStateToProps, BaseView } from '../lib/base_view'
import { request } from '../lib/utils'
import { Link } from 'react-router-dom'
import { ModalSearch } from './modal_search'


export class ReportBaseView extends BaseView {

  constructor(props) {
    super(props)

    this.onSearch = this.onSearch.bind(this)

    this.state = {
      title: undefined,
      tags: undefined,
      form: undefined,
      form_data: undefined,
      context: {},
      table_data: {},
    }

    // Маркер, который говорит о том, что какой-то
    this.wait_data = false
  }

  onSearch(form_data) {
    this.setState({ form_data: form_data, table_data: {}, context: {} })
  }

  setContext(src, context) {
    this.setState({ context: context })
  }

  renderTable({ data_name, change_context, links, thead, add_context }) {
    const that = this

    const data_key = add_context ? data_name + JSON.stringify(add_context) : data_name
    const data = this.state.table_data[data_key]

    if (data === undefined) {
      // Запрашиваем данные только если запрос не выполняется
      if (!this.wait_data) {
        this.wait_data = true
        let report_context = this.state.context

        if(add_context !== undefined) {
          report_context = {...report_context, ...add_context}
        }

        request({
          method: 'post',
          url: `/api/report/${this.name}/data`,
          data: {
            data_name: data_name,
            form: this.state.form_data,
            context: report_context,
          },
          success: (data) => {
            this.wait_data = false
            that.setState({ table_data: { ...that.state.table_data, [data_key]: data } })
          },
          error: (data) => {
            toastr.error(data, "Ошибка таблицы")
          },
        })
      }

      return (
        <div className="text-center">
          <div className="sk-bounce sk-primary">
            <div className="sk-bounce-dot"></div>
            <div className="sk-bounce-dot"></div>
          </div>
        </div>
      )
    }

    // Показываем только эти индексы колонок. Системные (начинаются на _) пропускаем
    let show_idx = []
    data.keys.forEach((key, index) => {
      if (key[0] !== "_") {
        show_idx.push(index)
      }
    });

    function renderCell(col_value, cell_idx, row_data) {
      if (!show_idx.includes(cell_idx))
        return null

      let cell_value
      const col_value_type = typeof col_value
      const col_title = data.keys[cell_idx]
      const get_context = change_context !== undefined ? change_context[col_title] : undefined
      const get_link = links !== undefined ? links[col_title] : undefined

      if (get_context !== undefined) {
        cell_value = <a className="pointer" onClick={() => { that.setContext(data_name, get_context(row_data)) }}>{col_value}</a>
      }
      else if (get_link !== undefined) {
        cell_value = <a href={get_link(row_data)} target="_blank">{col_value}</a>
      }
      else {
        cell_value = col_value
      }

      return <td key={cell_idx} className={col_value_type}>{cell_value}</td>
    }

    function renderTr(row, idx) {
      // Делаем объект с данными строки для построения ссылок
      let row_data = {}
      data.keys.forEach((key, index) => {
        row_data[key] = row[index];
      });

      return <tr key={idx}>
        {row.map((col_value, cell_idx) => { return renderCell(col_value, cell_idx, row_data) })}
      </tr>
    }

    return <table className="table table-condensed table-bordered report_table">
      {thead || <thead>
        <tr>
          {data.keys.map(
            (key, idx) => { return show_idx.includes(idx) ? <th key={key}>{key}</th> : null }
          )}
        </tr>
      </thead>}
      <tbody>
        {data.rows.map(renderTr)}
      </tbody>
    </table>
  }

  renderTopPanelLeftItems() {
    return this.state.form.length > 0 && (
      <span className="me-2" key="search">
        <a data-bs-toggle="modal" data-bs-target="#search_modal">
          <i className="ti ti-search ti-xs me-1"></i>
          Поиск
        </a>
      </span>
    )
  }

  renderBreadcrumbs() {
    const that = this
    return (
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb breadcrumb-style1">
          <li className="breadcrumb-item">
            <Link to="/">Домой</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/report">Отчеты</Link>
          </li>
          <li className="breadcrumb-item active">
            <a className="pointer" onClick={() => { that.setContext("breadcrumb", {}) }}>{this.state.title || '...'}</a>
          </li>
        </ol>
      </nav>
    )
  }

  renderModals() {
    if (this.state.form) {
      return <ModalSearch schema={this.state.form}
        data={this.state.form_data}
        onSubmit={this.onSearch} />
    }

    return null
  }

  waitRenderReady() {
    // Базовое
    const dummyContent = super.waitRenderReady()
    if (dummyContent) {
      return dummyContent
    }

    const that = this

    if (this.state.title === undefined) {
      request({
        method: 'get',
        url: `/api/report/${this.name}/meta`,
        success: (data) => {
          that.setState({
            title: data.title,
            form: data.form,
            tags: data.tags
          })
        },
        error: (data) => {
          toastr(data)
        },
      })
      return <span>Загрузка данных отчета...</span>
    }
  }

  renderContent() {
    return null  // Определяется в наследнике
  }

}

export function reportStateToProps(state) {
  const props = baseStateToProps(state)

  props.menu = state.report.menu
  props.tags = state.report.tags

  return props
}
